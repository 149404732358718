




















































































import type { MoodboardBuilderContent } from '@/inc/types'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'
import MoodboardBuilderTitle from '@/components/moodboard-builder/Title.vue'
import ProgressIndicator from '@/components/moodboard-builder/ProgressIndicator.vue'
import Carousel from '@/components/Carousel.vue'
import { STEP } from '@/views/MoodboardBuilder.vue'

const FROM_SCRATCH_VALUE = ''

export default defineComponent({
  name: 'Moods',
  components: {
    MoodboardBuilderTitle,
    ProgressIndicator,
    Carousel,
  },
  props: {
    content: {
      type: Object as PropType<MoodboardBuilderContent['moodboards']>,
      required: true,
    },
    moodTitle: {
      type: String,
      required: false,
    },
    progressA11yLabel: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { moodTitle: stateMoodTitle } = useGetters('moodbuilder', [
      'moodTitle',
    ])

    const { SET_MOOD_TITLE: setMoodId } = useMutations('moodbuilder', [
      'SET_MOOD_TITLE',
    ])

    const carouselRef = ref<InstanceType<typeof Carousel>>()
    const moodsListRef = ref()
    const selectedMoodTitle = ref(props.moodTitle ?? null)
    const currentIndex = ref(-1)

    const carouselItems = computed(() =>
      props.content.items.map(item => ({ carouselKey: item.title, ...item }))
    )

    const getMoodIndexFromTitle = (title: string) =>
      props.content.items.findIndex(mood => mood.title === title)

    // Select the previous mood, update index and set selectedMoodTitle based on index.
    const onClickPrev = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1
        setMoodFromCarouselIndex()
      }
    }

    // Select the next mood, update index and set selectedMoodTitle based on index.
    // Special casesince we append an extra item ("from scratch") that is not technically a mood.
    // If currentIndex.value === carouselItems.length, we allow to go up to (carouselItems.length + 1),
    // this will select the "from scratch" item.
    const onClickNext = () => {
      if (currentIndex.value <= carouselItems.value.length) {
        currentIndex.value += 1
        setMoodFromCarouselIndex()
      }
    }

    // Infer desired selectedMoodTitle from carousel index value.
    const setMoodFromCarouselIndex = () => {
      if (currentIndex.value < props.content.items.length) {
        selectedMoodTitle.value = props.content.items[currentIndex.value].title
      } else {
        // Start "from scratch" - No mood
        selectedMoodTitle.value = FROM_SCRATCH_VALUE
      }
    }

    // When selectedMoodTitle changes :
    // - update store
    // - make the carousel scroll to appropriate item
    watch(selectedMoodTitle, title => {
      setMoodId(title)

      if (title) {
        const index = getMoodIndexFromTitle(title)

        if (index >= 0) {
          currentIndex.value = index
          carouselRef.value?.goToSlide(currentIndex.value)
        }
      } else {
        // Select last entry (start from from scratch)
        currentIndex.value = carouselItems.value.length
        carouselRef.value?.scrollTo(Infinity)
      }
    })

    onMounted(() => {
      if (
        typeof stateMoodTitle.value === 'string' &&
        stateMoodTitle.value === ''
      ) {
        // We have already explicitely choosen "from scratch"
        currentIndex.value = carouselItems.value.length
        carouselRef.value?.scrollTo(Infinity, true)
      } else if (typeof stateMoodTitle.value === 'string') {
        // We have already explicitely choosen a mood
        const index = getMoodIndexFromTitle(stateMoodTitle.value)
        if (index >= 0) {
          currentIndex.value = index
          carouselRef.value?.goToSlide(currentIndex.value, true)
        }
      }
    })

    return {
      moodsListRef,
      selectedMoodTitle,
      stateMoodTitle,
      onClickPrev,
      onClickNext,
      FROM_SCRATCH_VALUE,
      carouselItems,
      carouselRef,
      currentIndex,
      STEP,
    }
  },
})
