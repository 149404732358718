var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"moods"},[_c('div',{staticClass:"moods__carousel"},[(_vm.content.logo)?_c('img',{directives:[{name:"src",rawName:"v-src",value:(_vm.content.logo),expression:"content.logo"}],staticClass:"moods__logo ms",attrs:{"width":_vm.content.logo.width,"height":_vm.content.logo.height,"alt":_vm.content.logo.description}}):_vm._e(),_c('Carousel',{ref:"carouselRef",staticClass:"moods__carousel",attrs:{"items":_vm.carouselItems,"bullet-points":false,"prev-next":false,"full-bleed":true,"fullBleedParent":null,"alignement":'center',"drag":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var mood = ref.content;
var index = ref.index;
return [_c('div',{staticClass:"mood"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedMoodTitle),expression:"selectedMoodTitle"}],staticClass:"mood__input",attrs:{"type":"radio","name":"mood","id":("mood-" + index)},domProps:{"value":mood.title,"checked":_vm._q(_vm.selectedMoodTitle,mood.title)},on:{"change":function($event){_vm.selectedMoodTitle=mood.title}}}),_c('label',{staticClass:"mood__label",attrs:{"for":("mood-" + index)}},[_c('p',{staticClass:"mood__title"},[_vm._v(_vm._s(mood.title))]),(mood.picture)?_c('img',{directives:[{name:"src",rawName:"v-src",value:(mood.picture),expression:"mood.picture"}],staticClass:"mood__picture",attrs:{"width":mood.picture.width,"height":mood.picture.height,"alt":mood.picture.description}}):_vm._e()])])]}}])})],1),_c('progress-indicator',{staticClass:"mt-s mb-xxxs",attrs:{"active-step":_vm.STEP.MOODS,"a11y-label":_vm.progressA11yLabel}}),_c('MoodboardBuilderTitle',{attrs:{"htmltext":_vm.content.title}}),_c('div',{staticClass:"moods__actions"},[_c('GAction',{class:{
        disabled: _vm.currentIndex === 0,
      },attrs:{"content":{
        icon: 'leftArrow',
        modifiers: ['btn', 'no-label'],
        tag: 'button',
      }},nativeOn:{"click":function($event){return _vm.onClickPrev.apply(null, arguments)}}}),_c('GAction',{class:{
        disabled: _vm.currentIndex >= _vm.content.items.length,
      },attrs:{"content":{
        icon: 'arrow',
        modifiers: ['btn', 'no-label'],
        tag: 'button',
      }},nativeOn:{"click":function($event){return _vm.onClickNext.apply(null, arguments)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }