import { GraphicsData, GraphicsGeometry } from 'pixi.js'

import { buildDashedLine } from './utils'
import type { IDashedLineStyleOptions } from './DashedGraphics'

export class DashedGeometry extends GraphicsGeometry {
  processLine(data: GraphicsData): void {
    if ((data.lineStyle as IDashedLineStyleOptions).dashArray) {
      buildDashedLine(data, this)
      for (let i = 0; i < data.holes.length; i++) {
        buildDashedLine(data.holes[i], this)
      }
    } else {
      super.processLine(data)
    }
  }
}
