






































































































































































import type { Material } from '@/inc/types'

import {
  defineComponent,
  PropType,
  watch,
  ref,
  computed,
} from '@vue/composition-api'

import Carousel from '@/components/Carousel.vue'
import MoodboardBuilderTitle from '@/components/moodboard-builder/Title.vue'
import ProgressIndicator from '@/components/moodboard-builder/ProgressIndicator.vue'

interface MaterialCategory {
  title?: string
  description?: string
  items: Material[]
}

export default defineComponent({
  name: 'Materials',
  components: {
    MoodboardBuilderTitle,
    Carousel,
    ProgressIndicator,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    categories: {
      type: Array as PropType<MaterialCategory[]>,
      required: true,
    },
    value: {
      type: Object as PropType<Material>,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    noneLabel: {
      type: String,
      required: false,
    },
    progressStep: {
      type: Number,
      required: true,
    },
    progressLabel: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const scrollerElRef = ref()
    const carouselRef = ref<InstanceType<typeof Carousel>>()
    const materialId = ref<number | null>(props.value?.id ?? null)
    const materials = props.categories.flatMap(category => category.items)
    const optional = computed(() => !props.required && props.noneLabel)
    const materialIndex = ref(optional.value ? -1 : 0)

    // When selecting new material, bubble update to MoodboardBuilder component
    watch(materialId, id => {
      if (id === null && !props.required) {
        emit('update', null)
        materialIndex.value = -1
      } else {
        const index = materials.findIndex(material => material.id === id)
        const material = materials[index]

        if (props.required && !material) {
          throw new Error(
            `Moodboard builder - Selected material does not exists. Id ${id}`
          )
        }

        emit('update', material)

        materialIndex.value = index
      }
    })

    const onClickPrev = () => {
      if (
        carouselRef.value &&
        carouselRef.value.isScrollable &&
        carouselRef.value.scrollerRef?.$el &&
        !carouselRef.value.prevButtonDisabled
      ) {
        carouselRef.value.scrollTo(
          Math.max(
            0,
            carouselRef.value.scrollerRef?.$el.scrollLeft -
              carouselRef.value.$el.clientWidth
          )
        )
      }
    }

    const onClickNext = () => {
      if (
        carouselRef.value &&
        carouselRef.value.isScrollable &&
        carouselRef.value.scrollerRef?.$el &&
        !carouselRef.value.nextButtonDisabled
      ) {
        carouselRef.value.scrollTo(
          carouselRef.value.scrollerRef.$el.scrollLeft +
            carouselRef.value.$el.clientWidth
        )
      }
    }

    const prevButtonDisabled = computed(() =>
      carouselRef.value ? carouselRef.value.prevButtonDisabled : true
    )

    const nextButtonDisabled = computed(() =>
      carouselRef.value ? carouselRef.value.nextButtonDisabled : true
    )

    const isScrollable = computed(() =>
      carouselRef.value ? carouselRef.value.isScrollable : true
    )

    const carouselItems = computed(() =>
      props.categories.map(category => ({
        carouselKey: category.title,
        ...category,
      }))
    )

    return {
      materialId,
      scrollerElRef,
      carouselRef,
      onClickPrev,
      onClickNext,
      optional,
      prevButtonDisabled,
      nextButtonDisabled,
      isScrollable,
      carouselItems,
    }
  },
})
