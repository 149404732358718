var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"rootRef",staticClass:"contact"},[_c('div',{staticClass:"contact__content"},[_c('MoodboardBuilderTitle',{staticClass:"contact__content__title",attrs:{"htmltext":_vm.content.title}}),(_vm.content.htmltext)?_c('div',{staticClass:"contact__content__text",domProps:{"innerHTML":_vm._s(_vm.content.htmltext)}}):_vm._e(),_c('ValidationObserver',{ref:"validationObserverRef",staticClass:"contact__form mt-s",attrs:{"tag":"div"}},[_vm._l((_vm.content.form),function(item,i){return [(item.slug == 'checkbox')?_c('FormCheckbox',{key:((item.name) + "-" + i),attrs:{"name":item.name,"required":item.required,"checked":false,"value":'confirm'},on:{"change":_vm.onDataInput}},[_c('span',{domProps:{"innerHTML":_vm._s(item.label)}}),(item.required)?_c('span',{staticClass:"form-label__required"},[_vm._v("*")]):_vm._e()]):_c('div',{key:((item.name) + "-" + i),staticClass:"contact__input"},[_c('FormInput',{attrs:{"type":item.type,"name":item.name,"label":item.label,"placeholder":item.placeholder,"required":item.required,"data":_vm.data[item.name]},on:{"input":_vm.onDataInput},model:{value:(_vm.data[item.name]),callback:function ($$v) {_vm.$set(_vm.data, item.name, $$v)},expression:"data[item.name]"}}),(item.name === 'email' && _vm.content.mailTooltip)?_c('FormTooltip',{attrs:{"content":_vm.content.mailTooltip,"display-on-side":false}}):_vm._e()],1)]})],2),(_vm.error)?_c('FormFeedback',{staticClass:"error-alert",attrs:{"content":_vm.error,"icon":"alert"}}):_vm._e(),_c('div',{staticClass:"desktop-actions"},[_c('GAction',{staticClass:"desktop-actions__action",attrs:{"content":{
          label: _vm.labelPrevious,
          icon: 'leftArrow',
          modifiers: ['btn', 'reverse'],
          tag: 'button',
        }},nativeOn:{"click":function($event){return _vm.$emit('prev')}}}),_c('GAction',{staticClass:"desktop-actions__action",class:{
          disabled: !_vm.validateStep,
          sending: _vm.formLoading,
        },attrs:{"content":{
          label: _vm.labelSubmit,
          loadingLabel: _vm.labelSubmit,
          icon: 'arrow',
          modifiers: ['btn', 'filled'],
          tag: 'button',
          type: 'submit',
        }},nativeOn:{"click":function($event){return _vm.$emit('submit')}}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }