import { Sprite, Assets, BlurFilter, Application, Graphics } from 'pixi.js'
import { fitAndPosition } from 'object-fit-math'

const BACKGROUND_FILL = 0xffffff

class Background {
  containerEl?: HTMLElement
  pixi!: Application
  backgroundPlane = new Graphics()
  sprite = new Sprite()

  constructor(containerEl?: HTMLElement, width?: number, height?: number) {
    this.initRenderer(containerEl, width, height)

    this.pixi.stage.addChild(this.backgroundPlane)

    const blurFilter = new BlurFilter()
    this.sprite.filters = [blurFilter]
    this.pixi.stage.addChild(this.sprite)

    this.drawBackgroundPlane()

    if (containerEl) {
      this.addCanvasToDOM(containerEl)
    }
  }

  private initRenderer(
    resizeTo?: HTMLElement,
    width?: number,
    height?: number
  ) {
    this.pixi = new Application({
      resizeTo,
      antialias: false,
      // If set higher, will result in blurry black artifacts near bottom and right edges.
      resolution: 1,
      backgroundColor: 0xffffff,
      width,
      height,
    })
  }

  private addCanvasToDOM(containerEl: HTMLElement) {
    const canvasEl = this.pixi.view as HTMLCanvasElement
    containerEl.appendChild(canvasEl)
  }

  public async setBackground(url: string) {
    // Load texture and assign to sprite
    const texture = await Assets.load(url)
    this.sprite.texture = texture

    // Set background cover scale and translation
    this.setBackgroundCover()
  }

  // Adujst sprite scale and translation in order for it to cover whole canvas
  // without streching. Like object-fit: cover
  public setBackgroundCover() {
    if (this.sprite) {
      // No need to divide width and height by resolution since we explicitly set it to one in background
      const { width, height } = this.pixi.view

      const cover = fitAndPosition(
        {
          width,
          height,
        },
        {
          width: this.sprite.texture.width,
          height: this.sprite.texture.height,
        },
        'cover',
        '50%',
        '50%'
      )

      const scaleX = cover.width / this.sprite.texture.width
      const scaleY = cover.height / this.sprite.texture.height
      const scale = Math.min(scaleX, scaleY)
      this.sprite.scale.set(scale, scale)

      this.sprite.position.set(cover.x, cover.y)
    }
  }

  public drawBackgroundPlane() {
    this.backgroundPlane.clear()
    this.backgroundPlane.beginFill(BACKGROUND_FILL)
    this.backgroundPlane.drawRect(
      0,
      0,
      this.pixi.view.width,
      this.pixi.view.height
    )
    this.backgroundPlane.endFill()
  }

  public onResize() {
    this.drawBackgroundPlane()
    this.setBackgroundCover()
  }

  public dispose() {
    this.pixi.destroy(true, {
      children: true,
      texture: true,
      baseTexture: true,
    })
  }
}

export { Background, BACKGROUND_FILL }
