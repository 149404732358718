

























































































































































































































































import type {
  Material,
  MoodboardStep,
  MoodboardBuilderStep,
  MoodboardBuilderContent,
  MoodboardDefinition,
} from '@/inc/types'

import {
  computed,
  defineComponent,
  ref,
  ComputedRef,
  onMounted,
  watch,
} from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'
import gsap from 'gsap'
import MoodboardBuilderHeader from '@/components/chrome/header/MoodboardBuilder.vue'
import Intro from '@/components/moodboard-builder/Intro.vue'
import Moods from '@/components/moodboard-builder/Moods.vue'
import MaterialPicker from '@/components/moodboard-builder/MaterialPicker.vue'
import Contact from '@/components/moodboard-builder/Contact.vue'
import Canvas from '@/components/moodboard-builder/Canvas.vue'
import Panel from '@/components/moodboard-builder/Panel.vue'
import Action from '@/components/g/Action.vue'
import axios, { AxiosError } from 'axios'
import { getApiUrl } from '@/inc/app.config'
import {
  setMaterialAverageColor,
  push,
  getMoodboardBuilderItemValue,
  campaignManager,
} from '@/inc/utils'

export const STEP = {
  INTRO: 0,
  MOODS: 1,
  WALL: 2,
  FLOOR: 3,
  PRIMARY: 4,
  SECONDARY: 5,
  TERTIARY: 6,
  ACCESSORY: 7,
  CONTACT: 8,
  OVERVIEW: 9,
  PREVIEW: 10,
}

// eslint-disable-next-line id-length
const materialPickerTransitionBP = 1024

export default defineComponent({
  name: 'MoodboardBuilder',
  components: {
    MoodboardBuilderHeader,
    Intro,
    Moods,
    MaterialPicker,
    Contact,
    Canvas,
    Panel,
  },
  setup(_props, ctx) {
    const { content, chrome } = useGetters(['content', 'chrome']) as {
      content: ComputedRef<MoodboardBuilderContent>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      chrome: ComputedRef<any>
    }
    const contactRef = ref<InstanceType<typeof Contact> | null>(null)
    const canvasRef = ref<InstanceType<typeof Canvas> | null>(null)
    const formLoading = ref(false)
    const moodboardCode = ref<string | null>()
    const step = ref(STEP.INTRO)
    const formSubmitError = ref('')
    const nextButtonRef = ref<InstanceType<typeof Action> | null>(null)
    const moodboardBuilderRef = ref<HTMLElement>()

    const {
      moodTitle,
      wallMaterial,
      floorMaterial,
      primaryMaterial,
      secondaryMaterial,
      tertiaryMaterial,
      accessoryMaterial,
      contact,
      projectType,
      projectTypeLabel,
    } = useGetters('moodbuilder', [
      'moodTitle',
      'wallMaterial',
      'floorMaterial',
      'primaryMaterial',
      'secondaryMaterial',
      'tertiaryMaterial',
      'accessoryMaterial',
      'contact',
      'projectType',
      'projectTypeLabel',
    ])

    const {
      SET_MOOD_TITLE: setMoodId,
      SET_WALL_MAT: setWallMaterial,
      SET_FLOOR_MAT: setFloorMaterial,
      SET_PRIMARY_MAT: setPrimaryMaterial,
      SET_SECONDARY_MAT: setSecondaryMaterial,
      SET_TERTIARY_MAT: setTertiaryMaterial,
      SET_ACCESSORY_MAT: setAccessoryMaterial,
      SET_PROJECT_TYPE: setProjectType,
    } = useMutations('moodbuilder', [
      'SET_MOOD_TITLE',
      'SET_WALL_MAT',
      'SET_FLOOR_MAT',
      'SET_PRIMARY_MAT',
      'SET_SECONDARY_MAT',
      'SET_TERTIARY_MAT',
      'SET_ACCESSORY_MAT',
      'SET_PROJECT_TYPE',
    ])

    const startBuilder = () => {
      step.value = STEP.MOODS
    }

    const wallMaterials = computed(() =>
      buildMaterialCatsBasedOnSelectedMood('wall')
    )

    const floorMaterials = computed(() =>
      buildMaterialCatsBasedOnSelectedMood('floor')
    )

    const primaryMaterials = computed(() =>
      buildMaterialCatsBasedOnSelectedMood('primary')
    )

    const secondaryMaterials = computed(() =>
      buildMaterialCatsBasedOnSelectedMood('secondary')
    )

    const tertiaryMaterials = computed(() =>
      buildMaterialCatsBasedOnSelectedMood('tertiary')
    )

    const accessoryMaterials = computed(() =>
      buildMaterialCatsBasedOnSelectedMood('accessory')
    )

    // eslint-disable-next-line id-length
    const buildMaterialCatsBasedOnSelectedMood = (stepId: string) => {
      const categories: { title?: string; items: Material[] }[] = []
      const step = content.value[stepId] as MoodboardBuilderStep
      const allMaterials = step.items

      // Find moodboard object based on selected moodboard
      const moodboard = content.value.moodboards.items.find(
        m => m.title === moodTitle.value
      )

      // eslint-disable-next-line id-length
      const createCategoryWithAllMaterials = (
        materials = allMaterials,
        title = ''
      ) => {
        categories.push({
          title,
          items: materials,
        })
      }

      const createFloorCategories = (materials = allMaterials) => {
        // Push a category will all ceramic floors
        categories.push({
          title: ctx.root.$i18n.t('moodboard-material-floor-ceramic') as string,
          items: materials.filter(m => m.kind === 'ceramic'),
        })

        // Push a category will all wooden floors
        categories.push({
          title: ctx.root.$i18n.t('moodboard-material-floor-wood') as string,
          items: materials.filter(m => m.kind === 'wood'),
        })
      }

      // Floor and material have special rules
      if (stepId === 'floor') {
        createFloorCategories()
      } else if (stepId === 'wall') {
        createCategoryWithAllMaterials(
          allMaterials,
          ctx.root.$i18n.t('moodboard-material-wall') as string
        )
      } else if (moodboard) {
        const highlighedMaterials = (moodboard[stepId] as MoodboardStep).items

        // eslint-disable-next-line id-length
        let notHighlightedMaterialsTitle = ''
        if (
          stepId === 'primary' ||
          stepId === 'secondary' ||
          stepId === 'tertiary'
        ) {
          notHighlightedMaterialsTitle = ctx.root.$i18n.t(
            'moodboard-material-other'
          ) as string
        } else if (stepId === 'accessory') {
          notHighlightedMaterialsTitle = ctx.root.$i18n.t(
            'moodboard-material-other-accessories'
          ) as string
        }

        // If we do have highlighted materials for current moodboard;
        // create highlighted and other categories.
        if (highlighedMaterials && highlighedMaterials.length > 0) {
          // Create a category containing the highlighted materials
          categories.push({
            title: moodboard[stepId].moodboardLabel,
            items: highlighedMaterials,
          })

          // Compute the difference between all materials and highlighted materials
          // (in other words, the not highlighted materials)
          const notHighlightedMaterials = allMaterials.filter(
            ({ id: id1 }) =>
              !highlighedMaterials.some(({ id: id2 }) => id2 === id1)
          )

          // Only create "Others" category if there is actually non-highlighted materials.
          // It will contain materials that are not highlighted by selected moodboard but are
          // still available to user
          if (notHighlightedMaterials.length > 0) {
            if (stepId === 'floor') {
              createFloorCategories(notHighlightedMaterials)
            } else {
              categories.push({
                title: notHighlightedMaterialsTitle,
                items: notHighlightedMaterials,
              })
            }
          }
        } else if (stepId === 'floor') {
          createFloorCategories()
        } else {
          createCategoryWithAllMaterials()
        }
      } else {
        createCategoryWithAllMaterials()
      }

      // Get average color for each material
      // Used for floor edges and text colors
      if (['floor', 'primary'].includes(stepId)) {
        categories.forEach(({ items }) => {
          items.forEach(item => {
            setMaterialAverageColor(item)
          })
        })
      }

      return categories
    }

    const goToPrevStep = () => {
      if (step.value > STEP.INTRO) {
        step.value -= 1
      }
    }

    const goToNextStep = async () => {
      if (validateStep.value) {
        if (step.value < STEP.CONTACT) {
          // camelCase util transforms moodboardStep1Category into moodboard_step1_category
          // which is not what is requested. To avoid any potential regression, use quoted "underscorecase"
          switch (step.value) {
            case STEP.MOODS:
              push({
                event: 'moodboard_builder_step_2',
                itemValue: getMoodboardBuilderItemValue(moodTitle.value),
                countStep: 1,
              })

              break
            case STEP.WALL:
              push({
                event: 'moodboard_builder_step_3',
                itemValue: getMoodboardBuilderItemValue(
                  wallMaterial.value?.title
                ),
                countStep: 1,
              })
              break
            case STEP.FLOOR:
              push({
                event: 'moodboard_builder_step_4',
                itemValue: getMoodboardBuilderItemValue(
                  floorMaterial.value?.title
                ),
                countStep: 1,
              })
              break
            case STEP.PRIMARY:
              push({
                event: 'moodboard_builder_step_5',
                itemValue: getMoodboardBuilderItemValue(
                  primaryMaterial.value?.title
                ),
                countStep: 1,
              })
              break
            case STEP.SECONDARY:
              push({
                event: 'moodboard_builder_step_6',
                itemValue: getMoodboardBuilderItemValue(
                  secondaryMaterial.value?.title
                ),
                countStep: 1,
              })
              break
            case STEP.TERTIARY:
              push({
                event: 'moodboard_builder_step_7',
                itemValue: getMoodboardBuilderItemValue(
                  tertiaryMaterial.value?.title
                ),
                countStep: 1,
              })
              break
            case STEP.ACCESSORY:
              push({
                event: 'moodboard_builder_step_8',
                itemValue: getMoodboardBuilderItemValue(
                  accessoryMaterial.value?.title
                ),
                countStep: 1,
              })
              break
            default:
              break
          }

          step.value += 1
        } else if (step.value === STEP.CONTACT) {
          try {
            push({
              event: 'moodboard_builder_submit',
              postalCode: contact.value?.zipcode,
            })

            await submitForm()

            step.value = STEP.OVERVIEW
          } catch (error) {
            formSubmitError.value = 'form-error'
            console.error(error)
          }
        }
      }
    }

    const submitForm = async () => {
      formSubmitError.value = ''

      // Visually indicates loading state
      formLoading.value = true

      // Analytics
      push({
        event: 'form-submit',
        eventCategory: 'Mooboard',
        eventAction: 'submit',
        eventLabel: 'mooboard-builder',
      })

      try {
        const picture = await canvasRef.value?.base64Screenshot()

        const definition: MoodboardDefinition = {
          version: 0,
          wall: wallMaterial.value,
          floor: floorMaterial.value,
          primary: primaryMaterial.value,
          secondary: secondaryMaterial.value,
          tertiary: tertiaryMaterial.value,
          accessory: accessoryMaterial.value,
          name: contact.value.name,
          project: projectTypeLabel.value,
        }

        if (moodTitle.value) {
          const mood = content.value.moodboards.items.find(
            m => m.title === moodTitle.value
          )
          if (mood?.picture) {
            definition.background = mood?.picture
          }
        }

        const response = await axios.post(`${getApiUrl()}/forms/moodboard`, {
          projectType: projectType.value,
          lastName: contact.value.lastname,
          firstName: contact.value.name,
          email: contact.value.email,
          postCode: contact.value.zipcode,
          picture,
          definition: JSON.stringify(definition),
          lang: `${ctx.root.$route.params.lang}-${process.env.VUE_APP_COUNTRY}`,
          // Campaign data
          ...campaignManager.getFormData('moodboard'),
        })

        console.log('form:success', response)

        // Visually indicates loading state
        formLoading.value = false

        if (response.data.success && response.data.errors.length === 0) {
          // Post is successfull, no errors.
          // Set moodboard code
          moodboardCode.value = response.data.code
          step.value = STEP.OVERVIEW

          ctx.root.$nextTick(() => {
            moodboardBuilderRef.value?.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          })
        } else {
          throw new Error(response.data.errors[0])
        }
      } catch (error) {
        // Visually indicates loading state
        formLoading.value = false

        throw new Error((error as AxiosError).message)
      }
    }

    const tooltip = computed(() => {
      switch (step.value) {
        case STEP.WALL:
          if (content.value.wall.text) {
            return {
              text: content.value.wall.text,
            }
          }
          break
        case STEP.FLOOR:
          if (content.value.floor.text) {
            return {
              text: content.value.floor.text,
            }
          }
          break
        case STEP.PRIMARY:
          if (content.value.primary.text) {
            return {
              text: content.value.primary.text,
            }
          }
          break
        case STEP.SECONDARY:
          if (content.value.secondary.text) {
            return {
              text: content.value.secondary.text,
            }
          }
          break
        case STEP.TERTIARY:
          if (content.value.tertiary.text) {
            return {
              text: content.value.tertiary.text,
            }
          }
          break
        case STEP.ACCESSORY:
          if (content.value.accessory.text) {
            return {
              text: content.value.accessory.text,
            }
          }
          break
        default:
          return null
      }

      return null
    })

    const validateStep = computed(() => {
      switch (step.value) {
        case STEP.MOODS:
          return typeof moodTitle.value === 'string'
        case STEP.PRIMARY:
          return primaryMaterial.value
        case STEP.CONTACT:
          return contactRef.value && contactRef.value.dataIsValid
        default:
          return true
      }
    })

    watch(validateStep, (isValid, wasValid) => {
      if (isValid && !wasValid && nextButtonRef.value?.$el) {
        gsap.effects.bounce(nextButtonRef.value.$el)
      }
    })

    const quoteUrl = computed(() => {
      if (chrome.value?.ctas?.headerRdv?.url) {
        if (moodboardCode.value) {
          return `${chrome.value.ctas.headerRdv.url}?moodboard=${moodboardCode.value}`
        }

        return `${chrome.value.ctas.headerRdv.url}`
      }

      return ''
    })

    // Tooltip
    const isOpen = ref(false)
    const onTooltipClick = () => {
      isOpen.value = !isOpen.value
    }
    const onTooltipClose = () => {
      isOpen.value = false
    }

    // Restart moodboard builder and reset state
    const onRestartMoodboard = () => {
      step.value = STEP.INTRO
      resetMoodboardState()
    }

    const resetMoodboardState = () => {
      setProjectType(null)
      setMoodId(null)
      setWallMaterial(null)
      setFloorMaterial(null)
      setPrimaryMaterial(null)
      setSecondaryMaterial(null)
      setTertiaryMaterial(null)
      setAccessoryMaterial(null)
    }

    // Set store moodId based on query param.
    // Note: should ideally use a slug instead of the title property but this does not exist yet.
    // see: https://git.epic.net/camber/website-v1/track/-/issues/667
    const setMoodIdFromQuery = () => {
      const moodSlugQuery = ctx.root.$route.query.mood
      if (moodSlugQuery && content.value.moodboards.items) {
        // Find if query value matches with any of the moods slug
        const mood = content.value.moodboards.items.find(
          mood => mood.slug === moodSlugQuery
        )

        // If we have a match, set value
        if (mood) {
          setMoodId(mood.title)
        }
      }
    }

    onMounted(() => {
      resetMoodboardState()
      setMoodIdFromQuery()
    })

    const onMaterialPickerLeave = (el: Element, done: () => void) => {
      if (innerWidth >= materialPickerTransitionBP) {
        const containerEl = el.querySelector('[data-picker-description]')
        if (containerEl) {
          gsap.to(containerEl, {
            x: -40,
            opacity: 0,
            duration: 0.25,
            ease: 'power2.in',
            onComplete: done,
          })
        }
      } else {
        done()
      }
    }

    const onMaterialPickerEnter = (el: Element, done: () => void) => {
      if (innerWidth >= materialPickerTransitionBP) {
        const TL = gsap.timeline({
          onComplete: done,
          defaults: {
            ease: 'power2.out',
            duration: 0.5,
          },
        })

        const containerEl = el.querySelector('[data-picker-description]')
        if (containerEl) {
          TL.fromTo(
            containerEl,
            {
              x: 40,
              opacity: 0,
            },
            {
              x: 0,
              opacity: 1,
            }
          )

          const secondaryTargets =
            containerEl.querySelectorAll('[data-animate-in]')
          if (secondaryTargets.length > 0) {
            TL.fromTo(
              secondaryTargets,
              {
                y: 20,
                opacity: 0,
              },
              {
                y: 0,
                opacity: 1,
                stagger: 0.1,
                delay: 0.1,
              },
              0
            )
          }
        }
      } else {
        done()
      }
    }

    return {
      step,
      startBuilder,
      setMoodId,
      setWallMaterial,
      setFloorMaterial,
      setPrimaryMaterial,
      setSecondaryMaterial,
      setTertiaryMaterial,
      setAccessoryMaterial,
      wallMaterial,
      floorMaterial,
      primaryMaterial,
      secondaryMaterial,
      tertiaryMaterial,
      accessoryMaterial,
      wallMaterials,
      floorMaterials,
      primaryMaterials,
      secondaryMaterials,
      tertiaryMaterials,
      accessoryMaterials,
      goToPrevStep,
      goToNextStep,
      tooltip,
      STEP,
      validateStep,
      moodTitle,
      contactRef,
      formLoading,
      moodboardCode,
      formSubmitError,
      content,
      canvasRef,
      quoteUrl,
      onRestartMoodboard,
      isOpen,
      onTooltipClick,
      onTooltipClose,
      nextButtonRef,
      onMaterialPickerLeave,
      onMaterialPickerEnter,
      moodboardBuilderRef,
    }
  },
})
