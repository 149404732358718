import { Application, Container } from 'pixi.js'
import {
  RoundedRectLayer,
  Layer,
  WallLayer,
  FloorLayer,
  TextLayer,
} from './Layer'
import { Background } from './Background'

// Those are the dimensions of the moodboard composition in figma
const MOODBOARD_NATURAL_WIDTH = 510
const MOODBOARD_NATURAL_HEIGHT = 510

class MoodboardBuilder {
  pixi!: Application
  background?: Background
  layers: Layer[] = []
  layersContainer = new Container()
  resolution: number

  constructor({
    foregroundContainerEl,
    backgroundContainerEl,
    width,
    height,
    resolution,
    background,
  }: {
    foregroundContainerEl?: HTMLElement
    backgroundContainerEl?: HTMLElement
    width?: number
    height?: number
    resolution: number
    background: boolean
  }) {
    this.onResize = this.onResize.bind(this)

    this.resolution = resolution

    this.initRenderer(foregroundContainerEl, width, height)
    if (background) {
      this.initBackground(backgroundContainerEl, width, height)
    }

    if (foregroundContainerEl) {
      this.addCanvasToDOM(foregroundContainerEl)
      window.addEventListener('resize', this.onResize)
    }
  }

  private initRenderer(
    resizeTo?: HTMLElement,
    width?: number,
    height?: number
  ) {
    this.pixi = new Application({
      resizeTo,
      antialias: true,
      backgroundAlpha: 0,
      resolution: this.resolution,
      width,
      height,
    })
  }

  private addCanvasToDOM(containerEl: HTMLElement) {
    const canvasEl = this.pixi.view as HTMLCanvasElement
    containerEl.appendChild(canvasEl)
  }

  private initBackground(
    backgroundContainerEl?: HTMLElement,
    width?: number,
    height?: number
  ) {
    this.background = new Background(backgroundContainerEl, width, height)
  }

  public onResize() {
    this.background?.onResize()
    this.centerLayers()
    this.scaleLayersToFitViewport()
    this.render()
  }

  public async addLayer(options) {
    const layer = new RoundedRectLayer(options)

    await layer.init({
      ...options,
      resolution: this.resolution,
    })

    this.layersContainer.addChild(layer.container)
    this.layers.push(layer)
  }

  public async addWallLayer(options) {
    const layer = new WallLayer(options)

    await layer.init({
      ...options,
      resolution: this.resolution,
    })

    this.layersContainer.addChild(layer.container)
    this.layers.push(layer)
  }

  public async addFloorLayer(options) {
    const layer = new FloorLayer(options)

    await layer.init({
      ...options,
      resolution: this.resolution,
    })

    this.layersContainer.addChild(layer.container)
    this.layers.push(layer)
  }

  public addTextLayer(options) {
    const layer = new TextLayer({
      ...options,
      resolution: this.resolution,
    })
    this.layersContainer.addChild(layer.container)
    this.layers.push(layer)
  }

  public hasLayer(id: string) {
    return Boolean(this.getLayer(id))
  }

  public getLayer(id: string) {
    return this.layers.find(layer => layer.id === id)
  }

  // Scale all layers based on viewport size so that everything fits within viewport.
  private scaleLayersToFitViewport() {
    let { width, height } = this.pixi.view
    width /= this.resolution
    height /= this.resolution

    const scale = Math.min(
      width / MOODBOARD_NATURAL_WIDTH,
      height / MOODBOARD_NATURAL_HEIGHT
    )
    this.layersContainer.scale.set(scale)
  }

  public dispose(texture: boolean) {
    this.pixi.destroy(true, {
      children: true,
      texture,
      baseTexture: true,
    })

    window.removeEventListener('resize', this.onResize)
  }

  public render() {
    this.layers.forEach(layer => {
      layer.render()
    })
  }

  public centerLayers() {
    let { width, height } = this.pixi.view
    width /= this.resolution
    height /= this.resolution

    this.layersContainer.position.set(width / 2, height / 2)
  }
}

export { MoodboardBuilder }
