var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"picker"},[_c('div',{staticClass:"picker__content",attrs:{"data-picker-description":""}},[_c('ProgressIndicator',{staticClass:"mb-xxxs",attrs:{"active-step":_vm.progressStep,"a11y-label":_vm.progressLabel,"data-animate-in":""}}),_c('MoodboardBuilderTitle',{staticClass:"picker__title",attrs:{"htmltext":_vm.title,"data-animate-in":""}}),(_vm.description)?_c('p',{staticClass:"picker__description",attrs:{"data-animate-in":""}},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()],1),_c('div',{staticClass:"picker__carousel"},[(_vm.isScrollable)?_c('button',{class:[
        'picker__button',
        {
          disabled: _vm.prevButtonDisabled,
        } ],on:{"click":_vm.onClickPrev}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"picker__button__icon",attrs:{"symbol":'ui-arrow-small-left',"size":24}})]):_vm._e(),_c('Carousel',{ref:"carouselRef",attrs:{"full-bleed":true,"fullBleedParent":null,"bullet-points":false,"prev-next":false,"items":_vm.carouselItems,"alignement":'left'},scopedSlots:_vm._u([(_vm.optional)?{key:"before",fn:function(){return [_c('li',{key:'none',staticClass:"carousel__item"},[_c('div',{staticClass:"picker__category js-slider-cell"},[_c('p',{staticClass:"picker__category__title"},[_vm._v(" "+_vm._s(_vm.noneLabel)+" ")]),_c('ul',{staticClass:"picker__list"},[_c('li',{staticClass:"picker__list__item material"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.materialId),expression:"materialId"}],staticClass:"material__input",attrs:{"type":"radio","name":"material","id":"material-none"},domProps:{"value":null,"checked":_vm._q(_vm.materialId,null)},on:{"change":function($event){_vm.materialId=null}}}),_c('label',{class:['material__label', 'has-icon'],attrs:{"for":"material-none"}},[_c('span',[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"material__icon",attrs:{"size":"0 0 24 24","symbol":'ui-close'}}),_c('span',{staticClass:"visually-hidden"},[_vm._v(" "+_vm._s(_vm.noneLabel)+" ")])])])])])])])]},proxy:true}:null,{key:"item",fn:function(ref){
      var category = ref.content;
      var index = ref.index;
return [_c('div',{key:((category.title) + "-" + index),staticClass:"picker__category"},[(category.title)?_c('p',{staticClass:"picker__category__title"},[_vm._v(" "+_vm._s(category.title)+" ")]):_vm._e(),_c('ul',{staticClass:"picker__list"},_vm._l((category.items),function(material,i){return _c('li',{key:("material-" + (material.id) + "-" + i),staticClass:"picker__list__item material"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.materialId),expression:"materialId"}],staticClass:"material__input",attrs:{"type":"radio","name":"material","id":("material-" + (material.id) + "-" + i)},domProps:{"value":material.id,"checked":_vm._q(_vm.materialId,material.id)},on:{"change":function($event){_vm.materialId=material.id}}}),_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  content: material.title,
                  triggers: ['hover'],
                  distance: 5,
                  delay: 0,
                }),expression:"{\n                  content: material.title,\n                  triggers: ['hover'],\n                  distance: 5,\n                  delay: 0,\n                }",modifiers:{"top":true}}],staticClass:"material__label",attrs:{"for":("material-" + (material.id) + "-" + i)}},[(material.picture)?_c('div',{staticClass:"material__thumbnail-container"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(material.picture),expression:"material.picture"}],staticClass:"material__thumbnail",attrs:{"width":"50","height":"50","sets":"['160']","alt":material.title,"title":material.title}}),_c('span',{staticClass:"visually-hidden"},[_vm._v(_vm._s(material.title))])]):(material.color)?_c('div',{staticClass:"material__thumbnail-container"},[_c('div',{staticClass:"material__thumbnail",style:(("background-color: " + (material.color) + ";"))}),_c('span',{staticClass:"visually-hidden"},[_vm._v(_vm._s(material.title))])]):_vm._e()])])}),0)])]}}],null,true)}),(_vm.isScrollable)?_c('button',{class:[
        'picker__button',
        {
          disabled: _vm.nextButtonDisabled,
        } ],on:{"click":_vm.onClickNext}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"picker__button__icon",attrs:{"symbol":'ui-arrow-small-right',"size":24}})]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }