









import type { Material, MoodboardBuilderContent } from '@/inc/types'
import type { TextLayer } from '@/inc/moodboard-builder/Layer'

import { useGetters } from '@u3u/vue-hooks'
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  PropType,
  onBeforeUnmount,
  watch,
} from '@vue/composition-api'
import { STEP } from '@/views/MoodboardBuilder.vue'
import { MoodboardBuilder } from '@/inc/moodboard-builder'
import { BACKGROUND_FILL } from '@/inc/moodboard-builder/Background'
import { Assets, BlurFilter, Graphics, Sprite } from 'pixi.js'
import { fitAndPosition } from 'object-fit-math'

// LAYER_ID must share the same keys as STEP object.
const LAYER_ID = {
  WALL: 'layer-wall',
  FLOOR: 'layer-floor',
  PRIMARY: 'layer-primary',
  SECONDARY: 'layer-secondary',
  TERTIARY: 'layer-tertiary',
  ACCESSORY: 'layer-accessory',
  TEXT: 'layer-text',
}

const materialSet = '640'

export default defineComponent({
  name: 'MoodboardCanvas',
  props: {
    content: {
      type: Object as PropType<MoodboardBuilderContent>,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  setup(props, ctx) {
    const foregroundContainerRef = ref<HTMLElement | undefined>()
    const backgroundContainerRef = ref<HTMLElement | undefined>()
    let moodboardBuilder: MoodboardBuilder
    const lightBackground = ref(true)
    const isPreview = computed(() => props.step === STEP.PREVIEW)

    const {
      moodTitle,
      projectTypeLabel,
      wallMaterial,
      floorMaterial,
      primaryMaterial,
      secondaryMaterial,
      tertiaryMaterial,
      accessoryMaterial,
      contact,
    } = useGetters('moodbuilder', [
      'moodTitle',
      'projectTypeLabel',
      'wallMaterial',
      'floorMaterial',
      'primaryMaterial',
      'secondaryMaterial',
      'tertiaryMaterial',
      'accessoryMaterial',
      'contact',
    ])

    const layers = [
      {
        material: wallMaterial,
        layerId: LAYER_ID.WALL,
      },
      {
        material: floorMaterial,
        layerId: LAYER_ID.FLOOR,
      },
      {
        material: primaryMaterial,
        layerId: LAYER_ID.PRIMARY,
      },
      {
        material: secondaryMaterial,
        layerId: LAYER_ID.SECONDARY,
      },
      {
        material: tertiaryMaterial,
        layerId: LAYER_ID.TERTIARY,
      },
      {
        material: accessoryMaterial,
        layerId: LAYER_ID.ACCESSORY,
      },
      {
        material: null,
        layerId: LAYER_ID.TEXT,
      },
    ]

    onMounted(async () => {
      moodboardBuilder = new MoodboardBuilder({
        foregroundContainerEl: foregroundContainerRef.value!,
        backgroundContainerEl: backgroundContainerRef.value!,
        resolution: window.devicePixelRatio,
        background: true,
      })
      const mood = props.content?.moodboards.items.find(
        mood => mood.title === moodTitle.value
      )
      if (mood?.picture?.src) {
        setBackground(mood.picture.src)
      }
      await createLayers(moodboardBuilder, false)
      onStepUpdate()
      moodboardBuilder.onResize()
      moodboardBuilder.render()
    })

    onBeforeUnmount(() => {
      moodboardBuilder?.dispose(true)
    })

    // Provide selected mood picture url to the canvas so that it can render it
    const setBackground = (src: string) => {
      lightBackground.value = false
      moodboardBuilder.background?.setBackground(src)
    }

    // eslint-disable-next-line id-length
    const getTextureSrcFromMaterial = (material?: Material) =>
      material?.picture?.sets[materialSet]

    // Create all the differents layers of the moodboard
    const createLayers = async (mb: MoodboardBuilder, offscreen = false) => {
      const radius = 25
      const wallY = -30
      const wallWidth = 360
      const wallHeight = 246
      const floorHeight = 105
      const floorWidth = 405
      const primarySize = 270
      const textPadding = 20

      // Addition is async to be sure that the texture is loaded (needed for preview)
      // Order matters here, layers are rendered in the order they are added.
      await mb.addWallLayer({
        id: LAYER_ID.WALL,
        width: wallWidth,
        height: wallHeight,
        x: 0,
        y: wallY,
        radius: radius * 2,
        texture: getTextureSrcFromMaterial(wallMaterial.value),
      })
      await mb.addFloorLayer({
        id: LAYER_ID.FLOOR,
        width: wallWidth,
        baseWidth: floorWidth,
        height: floorHeight,
        x: 0,
        // eslint-disable-next-line no-mixed-operators
        y: wallY * 2 + wallHeight / 2 + floorHeight / 2,
        radius: 0.25,
        texture: getTextureSrcFromMaterial(floorMaterial.value),
      })
      // Force visual update, needed for edge colors
      await updateMaterialLayer(
        LAYER_ID.FLOOR,
        floorMaterial.value,
        mb,
        !offscreen
      )
      await mb.addLayer({
        id: LAYER_ID.PRIMARY,
        width: primarySize,
        height: primarySize,
        x: 0,
        y: 0,
        radius,
        texture: getTextureSrcFromMaterial(primaryMaterial.value),
      })
      // Force visual update, needed for text colors
      await updateMaterialLayer(
        LAYER_ID.PRIMARY,
        primaryMaterial.value,
        mb,
        !offscreen
      )
      await mb.addLayer({
        id: LAYER_ID.SECONDARY,
        width: 180,
        height: 180,
        x: -135,
        y: -135,
        radius,
        texture: getTextureSrcFromMaterial(secondaryMaterial.value),
      })
      await mb.addLayer({
        id: LAYER_ID.TERTIARY,
        width: 90,
        height: 90,
        x: 135,
        y: 115,
        radius,
        texture: getTextureSrcFromMaterial(tertiaryMaterial.value),
      })
      await mb.addLayer({
        id: LAYER_ID.ACCESSORY,
        width: 120,
        height: 120,
        x: 110,
        y: -75,
        radius,
        texture: getTextureSrcFromMaterial(accessoryMaterial.value),
      })
      mb.addTextLayer({
        id: LAYER_ID.TEXT,
        width: primarySize,
        height: primarySize,
        x: 0,
        y: 0,
        radius: 0,
        padding: textPadding,
        headline: ctx.root.$i18n.t('the-moodboard-of'),
        name: isPreview.value ? contact.value.name : undefined,
        project: projectTypeLabel.value,
      })

      if (isPreview.value || offscreen) {
        // If preview, do not watch (below)
        // for name to be set via the form
        // to show text layer
        updateTextLayer(true, mb, !offscreen)
      }

      mb.pixi.stage.addChild(mb.layersContainer)
    }

    const onStepUpdate = () => {
      // Set active layer and toggle visibility based on current step.
      // For layer matching with current step + text layer : set active and show.
      // For every other layers : deactivate + show if has texture, hide otherwise.
      Object.keys(STEP).forEach(key => {
        if (props.step === STEP[key]) {
          moodboardBuilder.layers.forEach(layer => {
            if (layer.id === LAYER_ID[key] || layer.id === LAYER_ID.TEXT) {
              layer.activate()
              layer.show()
            } else {
              layer.deactivate()
              layer.showIfVisual()
            }
          })
        }
      })

      // Canvases dimensions changes depending on current step.
      // Whenever step changes, recompute dimensions and trigger render on both background and foreground.
      moodboardBuilder.background?.pixi.resize()
      moodboardBuilder.pixi.resize()
      moodboardBuilder.onResize()
    }

    const updateMaterialLayer = async (
      layerId: string,
      material: Material | null,
      mb: MoodboardBuilder = moodboardBuilder,
      animate: boolean
    ) => {
      const layer = mb?.getLayer(layerId)

      if (layer) {
        const color = material?.color
        let colorAverage: undefined | Promise<string> = undefined
        let texture: undefined | string = undefined

        if (material) {
          texture = getTextureSrcFromMaterial(material)
          colorAverage =
            material.colorAverage instanceof Promise
              ? material.colorAverage
              : undefined
        }

        await layer.updateVisual({ texture, color, colorAverage })

        // If we're updating floor layer and text layer has a name already : also update text layer.
        // Text position might need to change if we remove or add layer.
        if (layerId === LAYER_ID.FLOOR) {
          const textLayer = mb?.getLayer(LAYER_ID.TEXT) as TextLayer | undefined
          if (textLayer?.name) {
            updateTextLayer(false, mb, animate)
          }
        }
      }
    }

    const updateTextLayer = (
      init = false,
      mb: MoodboardBuilder = moodboardBuilder,
      animate
    ) => {
      // Get layers for colors
      const primaryLayer = mb?.getLayer(LAYER_ID.PRIMARY)
      const floorLayer = mb?.getLayer(LAYER_ID.FLOOR)
      const textLayer = mb?.getLayer(LAYER_ID.TEXT) as TextLayer

      if (!primaryLayer || !floorLayer || !textLayer) {
        return
      }

      const primaryColor = primaryLayer.colorAverage
      const floorColor = floorLayer.colorAverage

      textLayer.updateText(
        { name: contact.value.name, primaryColor, floorColor },
        init,
        animate
      )
    }

    watch(
      () => props.step,
      () => {
        onStepUpdate()
        moodboardBuilder.render()
      }
    )

    // Register a watcher for each material.
    // When it changes, update its layer texture accordingly
    layers.forEach(({ material, layerId }) => {
      material &&
        watch(material, () => {
          updateMaterialLayer(layerId, material.value, moodboardBuilder, true)
        })
    })

    // Watch for name change
    watch(
      () => contact.value.name,
      (value, old) => {
        console.log('name change')
        // Show with animation when start typing
        updateTextLayer(value !== '' && old === '', moodboardBuilder, true)
      }
    )

    // Generate a base4 screenshot of moodboard + background.
    // Instanciate new "headless" MoodboardBuilder w same config.
    // Screenshot background + forground, compose those screenshot onto new canvas
    // which gets converted to base64.
    const base64Screenshot = async () => {
      // Settings of screenshot.
      // Those can be tweaked to increase quality / performance.
      const width = 1024
      const height = 576

      const offscreenMoodboard = new MoodboardBuilder({
        width,
        height,
        resolution: 1,
        background: false,
      })

      // Initialize background
      const mood = props.content?.moodboards.items.find(
        mood => mood.title === moodTitle.value
      )
      if (mood?.picture?.src) {
        // Load texture and assign to sprite
        const texture = await Assets.load(mood.picture.src)

        const sprite = new Sprite(texture)
        sprite.filters = [new BlurFilter()]

        const { width, height } = offscreenMoodboard.pixi.view

        const cover = fitAndPosition(
          {
            width,
            height,
          },
          {
            width: sprite.texture.width,
            height: sprite.texture.height,
          },
          'cover',
          '50%',
          '50%'
        )

        const scaleX = cover.width / sprite.texture.width
        const scaleY = cover.height / sprite.texture.height
        const scale = Math.min(scaleX, scaleY)
        sprite.scale.set(scale, scale)
        sprite.position.set(cover.x, cover.y)

        offscreenMoodboard.pixi.stage.addChild(sprite)
      } else {
        // If no mood is defined, draw solid background instead
        const backgroundPlane = new Graphics()
        offscreenMoodboard.pixi.stage.addChild(backgroundPlane)
        backgroundPlane.beginFill(BACKGROUND_FILL)
        backgroundPlane.drawRect(
          0,
          0,
          offscreenMoodboard.pixi.view.width,
          offscreenMoodboard.pixi.view.height
        )
        backgroundPlane.endFill()
      }

      // Initialize layers
      await createLayers(offscreenMoodboard, true)

      offscreenMoodboard.layers.forEach(layer => {
        layer.showIfVisual()
      })

      // Manually trigger resize to corretly set scale and position of layers
      offscreenMoodboard.onResize()

      // Wait for all the textures to be loaded
      const promises: Promise<unknown>[] = []

      offscreenMoodboard.layers.forEach(layer => {
        if (layer.texturePromise) {
          promises.push(layer.texturePromise)
        }
      })

      await Promise.all(promises)

      const b64 = await offscreenMoodboard.pixi.renderer.extract.base64(
        offscreenMoodboard.pixi.stage,
        'image/jpeg',
        0.9
      )

      // Do not delete textures when disposing of offscreen moodboard otherwise
      // they won't be available for the onscreen canvas
      offscreenMoodboard.dispose(false)

      return b64
    }

    return {
      foregroundContainerRef,
      backgroundContainerRef,
      base64Screenshot,
      setBackground,
      lightBackground,
    }
  },
})
