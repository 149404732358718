




























































































import type { MoodboardBuilderContent } from '@/inc/types'

import {
  PropType,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { useMutations, useGetters, useActions } from '@u3u/vue-hooks'

import FormInput from '@/components/form/Input.vue'
import FormTooltip from '@/components/form/Tooltip.vue'
import FormCheckbox from '@/components/form/Checkbox.vue'
import MoodboardBuilderTitle from '@/components/moodboard-builder/Title.vue'
import FormFeedback from '@/components/form/Feedback.vue'
import { StorageUtils } from '@/inc/plugins/storage'
import type { PersonalData } from '@/inc/store/modules/steps'

export default defineComponent({
  name: 'Contact',
  components: {
    FormInput,
    FormTooltip,
    MoodboardBuilderTitle,
    ValidationObserver,
    FormFeedback,
    FormCheckbox,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    progressA11yLabel: {
      type: String,
      required: true,
    },
    content: {
      type: Object as PropType<MoodboardBuilderContent['contact']>,
      required: true,
    },
    error: {
      type: String,
      requird: false,
    },
    formLoading: {
      type: Boolean,
      requireed: true,
    },
    validateStep: {
      type: Boolean,
      requireed: true,
    },
    labelPrevious: {
      type: String,
      required: true,
    },
    labelSubmit: {
      type: String,
      required: true,
    },
  },
  setup(_props, ctx) {
    const { SET_CONTACT: setContact } = useMutations('moodbuilder', [
      'SET_CONTACT',
    ])
    const rootRef = ref<HTMLElement>()
    const { contact } = useGetters('moodbuilder', ['contact'])

    const data = reactive({
      lastname: contact.value.lastname,
      name: contact.value.name,
      email: contact.value.email,
      zipcode: contact.value.zipcode,
    })

    // Use the ValidationObserver to validate form content.
    // https://vee-validate.logaretm.com/v3/api/validation-observer.html
    // Must wait for DOM to be updated otherwise it won't work (hence nextTick).
    const dataIsValid = ref(false)
    const validationObserverRef = ref()
    const onDataInput = async () => {
      setContact(data)

      saveDataInStepStore()

      await ctx.root.$nextTick()
      silentlyValidateData()
    }

    const silentlyValidateData = async () => {
      await validationObserverRef.value.validate({
        // Do not show error message here
        silent: true,
      })

      dataIsValid.value =
        rootRef.value?.querySelectorAll('.is-invalid').length === 0
    }

    onMounted(async () => {
      await ctx.root.$nextTick()
      silentlyValidateData()
    })

    const { $storage } = ctx.root.$options
    const { personal } = useGetters('steps', ['personal'])
    const savedStep = ref<Partial<PersonalData>>(
      personal.value($storage!) ? personal.value($storage!) : {}
    )
    const { addPersonal } = useActions('steps', ['addPersonal', 'addProject'])

    // Save data in step store so that user does not have to input it again
    // when filling contact form (demande de devis).
    const saveDataInStepStore = () => {
      const step = {
        lastName: data.lastname,
        firstName: data.name,
        email: data.email,
        zip: data.zipcode,
      }

      addPersonal({
        data: {
          ...savedStep.value,
          ...step,
        },
        storage: $storage as StorageUtils,
      })
    }

    return {
      data,
      onDataInput,
      dataIsValid,
      validationObserverRef,
      rootRef,
    }
  },
})
