










































import { defineComponent } from '@vue/composition-api'
import ChromeHeaderLang from '@/components/chrome/header/Lang.vue'

export default defineComponent({
  name: 'chrome-header-moodboardbuilder',
  components: {
    ChromeHeaderLang,
  },
})
