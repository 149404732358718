import { LineStyle } from 'pixi.js'

export class DashedLineStyle extends LineStyle {
  public dashArray: number[] | null = null
  public dashOffset = 0
  public dashAuto = false

  clone() {
    const obj = new DashedLineStyle()

    obj.color = this.color
    obj.alpha = this.alpha
    obj.texture = this.texture
    obj.matrix = this.matrix
    obj.visible = this.visible
    obj.width = this.width
    obj.alignment = this.alignment
    obj.native = this.native
    obj.cap = this.cap
    obj.join = this.join
    obj.miterLimit = this.miterLimit
    obj.dashArray = this.dashArray
    obj.dashOffset = this.dashOffset
    obj.dashAuto = this.dashAuto

    return obj
  }

  reset() {
    super.reset()

    this.dashArray = null
    this.dashOffset = 0
    this.dashAuto = false
  }
}
