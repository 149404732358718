








































































import type { MoodboardBuilderContent } from '@/inc/types'
import type { ProjectData } from '@/inc/store/modules/steps'

import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { useActions, useGetters, useMutations } from '@u3u/vue-hooks'

import FormSelect from '@/components/form/Select.vue'
import MoodboardBuilderTitle from '@/components/moodboard-builder/Title.vue'
import Action from '@/components/g/Action.vue'
import gsap from 'gsap'
import { StorageUtils } from '@/inc/plugins/storage'
import { push, getMoodboardBuilderItemValue } from '@/inc/utils'

export default defineComponent({
  name: 'Intro',
  props: {
    content: {
      type: Object as PropType<MoodboardBuilderContent['intro']>,
      required: true,
    },
  },
  components: {
    MoodboardBuilderTitle,
    FormSelect,
  },
  setup(props, ctx) {
    const getters = useGetters('moodbuilder', ['projectType'])
    const projectType = ref(getters.projectType.value)
    const buttonRef = ref<InstanceType<typeof Action> | null>(null)

    const { SET_PROJECT_TYPE: setProjectType } = useMutations('moodbuilder', [
      'SET_PROJECT_TYPE',
    ])

    const { $storage } = ctx.root.$options
    const { project } = useGetters('steps', ['project'])
    const savedStep = ref<Partial<ProjectData>>(
      project.value($storage!) ? project.value($storage!) : {}
    )
    const { addProject } = useActions('steps', ['addProject'])

    const onClickStart = () => {
      const type = projectType.value
      const label = props.content.projectType.options.find(
        option => option.value === type
      )?.label

      setProjectType({ type, label })

      push({
        event: 'moodboard_builder_step_1',
        itemValue: getMoodboardBuilderItemValue(type),
        countStep: 1,
      })

      ctx.emit('start')
    }

    const label = props.content.projectType.title ?? ''

    watch(projectType, (hasType, hadType) => {
      saveDataInStepStore()

      if (hasType && !hadType && buttonRef.value?.$el) {
        gsap.effects.bounce(buttonRef.value.$el)
      }
    })

    // Save data in step store so that user does not have to input it again
    // when filling contact form (demande de devis).
    // We retrieve current step and only update the categories.
    const saveDataInStepStore = () => {
      const category = props.content.projectType.options.find(
        o => o.value === projectType.value
      )
      if (category) {
        const data: ProjectData = {
          categories: {
            // ? Review
            // SecondStepCategories tells us that items should be an array of strings but
            // reality tells us otherwise...
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            items: [category],
            value: category.value,
          },
        }
        addProject({
          data: {
            ...savedStep.value,
            ...data,
          },
          storage: $storage as StorageUtils,
        })
      }
    }

    return {
      projectType,
      onClickStart,
      label,
      buttonRef,
    }
  },
})
