var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"intro"},[(_vm.content.logo)?_c('img',{directives:[{name:"src",rawName:"v-src",value:(_vm.content.logo),expression:"content.logo"}],staticClass:"intro__logo ms",attrs:{"width":_vm.content.logo.width,"height":_vm.content.logo.height,"alt":_vm.content.logo.description}}):_vm._e(),_c('moodboard-builder-title',{staticClass:"intro__title mb-s",attrs:{"htmltext":_vm.content.title}}),_c('div',{staticClass:"intro__text mb-s",domProps:{"innerHTML":_vm._s(_vm.content.htmltext)}}),_c('FormSelect',{staticClass:"mb-s",attrs:{"name":_vm.content.projectType.name,"options":_vm.content.projectType.options,"title":_vm.label,"label":_vm.label,"required":_vm.content.projectType.required,"auto-placement":true},model:{value:(_vm.projectType),callback:function ($$v) {_vm.projectType=$$v},expression:"projectType"}}),_c('GAction',{ref:"buttonRef",class:{
      disabled: !_vm.projectType,
    },attrs:{"content":{
      label: _vm.content.cta,
      icon: 'arrow',
      modifiers: ['btn', 'filled', 'full-width'],
      tag: 'button',
    }},nativeOn:{"click":function($event){return _vm.onClickStart.apply(null, arguments)}}}),(_vm.content.picture1)?_c('img',{directives:[{name:"src",rawName:"v-src",value:(_vm.content.picture1),expression:"content.picture1"}],staticClass:"intro__picture--1",attrs:{"width":_vm.content.picture1.width,"height":_vm.content.picture1.height,"alt":_vm.content.picture1.description}}):_vm._e(),(_vm.content.picture2)?_c('img',{directives:[{name:"src",rawName:"v-src",value:(_vm.content.picture2),expression:"content.picture2"}],staticClass:"intro__picture--2",attrs:{"width":_vm.content.picture2.width,"height":_vm.content.picture2.height,"alt":_vm.content.picture2.description}}):_vm._e(),(_vm.content.picture2)?_c('img',{directives:[{name:"src",rawName:"v-src",value:(_vm.content.picture2),expression:"content.picture2"}],staticClass:"intro__picture--3",attrs:{"width":_vm.content.picture2.width,"height":_vm.content.picture2.height,"alt":_vm.content.picture2.description}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }