/* eslint-disable no-mixed-operators */
import type { ITextStyle } from '@pixi/text'

import { DropShadowFilter } from '@pixi/filter-drop-shadow'
import gsap from 'gsap'
import { Container, Text, Texture, Matrix, Assets } from 'pixi.js'
import { fitAndPosition } from 'object-fit-math'

import { DashedGraphics } from './DashedGraphics'
import {
  adjustBrightness,
  getBrightness,
  getDistance,
  getPointAt,
} from '@/inc/utils'

export interface LayerConstructorOptions {
  id: string
  width: number
  height: number
  x: number
  y: number
  radius: number
  texture?: string
  color?: string
  colorAverage?: Promise<string>
  resolution: number
}

export interface FloorLayerConstructorOptions extends LayerConstructorOptions {
  baseWidth: number
}

export interface TextLayerConstructorOptions extends LayerConstructorOptions {
  padding: number
  headline: string
  name?: string
  project: string
}

const placeholderStrokeWidth = 4
const placeholderStrokeColor = 0xfcaf17
const placeholderStrokeAlpha = 1
const placeholderFillColor = 0xffffff
const placeholderFillAlpha = 0.2
const placeholderDashArray = [10, 10]

// We cannot instanciate DropShadowFilter here because it will break SSR since it relies on document.
// To go around issue, wrap instantion in getDropShadowFilter "singleton factory" method.
// Idea is to reuse same instance for every layer instead of creating a new one each time.
let dropShadowFilter: DropShadowFilter | undefined = undefined
const getDropShadowFilter = (resolution: number) => {
  if (!dropShadowFilter) {
    dropShadowFilter = new DropShadowFilter({
      offset: {
        x: 0,
        y: 10,
      },
      alpha: 0.25,
      blur: 6,
      quality: 7,
      resolution,
    })
  }

  return dropShadowFilter
}

abstract class Layer {
  public abstract render(): void

  id: string
  width: number
  height: number
  x: number
  y: number
  radius: number
  graphics = new DashedGraphics()
  container = new Container()
  textureFillMatrix = new Matrix()
  texture?: Texture
  textureIsLoading = false
  color?: string
  colorAverage?: Promise<string>
  active = false
  texturePromise?: Promise<Texture>

  constructor(options: LayerConstructorOptions) {
    this.id = options.id
    this.width = options.width
    this.height = options.height
    this.x = options.x
    this.y = options.y
    this.radius = options.radius
  }

  public async init(options: LayerConstructorOptions) {
    await this.updateVisual(options, true)
    this.container.addChild(this.graphics)
    this.container.filters = [getDropShadowFilter(options.resolution)]
  }

  // Set texture from src, remove current if nothing is provided
  public async setTexture(src?: string) {
    if (src) {
      this.textureIsLoading = true
      this.texturePromise = Assets.load(src)
      this.texture = await this.texturePromise
    } else {
      this.texturePromise = undefined
      this.texture = undefined
    }
  }
  // Set color, remove current if nothing is provided
  protected setColor(color?: string) {
    this.color = color
  }
  // Set color, remove current if nothing is provided
  protected setColorAverage(colorAverage?: Promise<string>) {
    this.colorAverage = colorAverage
  }

  // If texture is set, adjust its matrix in order for the texture to
  // cover the layer without streching its apsect ratio.
  // eslint-disable-next-line id-length
  protected setTextureFillMatrixTransform(customWidth?: number) {
    if (!this.texture) {
      return
    }

    const cover = fitAndPosition(
      {
        width: customWidth || this.width,
        height: this.height,
      },
      {
        width: this.texture.width,
        height: this.texture.height,
      },
      'cover',
      '50%',
      '50%'
    )

    this.textureFillMatrix.setTransform(
      cover.x - (customWidth ? (customWidth - this.width) / 2 : 0),
      cover.y,
      0,
      0,
      cover.width / this.texture.width,
      cover.height / this.texture.height,
      0,
      0,
      0
    )
  }

  // Add or remove texture / color
  public async updateVisual(
    {
      texture,
      color,
      colorAverage,
    }: {
      texture?: string
      color?: string
      colorAverage?: Promise<string>
    },
    init = false
  ) {
    await this.setTexture(texture)
    this.setColor(color)
    this.setColorAverage(colorAverage)
    !init && this.render()
  }

  public activate() {
    this.active = true
  }

  public deactivate() {
    this.active = false
  }

  public show(animate = false) {
    if (animate) {
      this.container.alpha = 0
      this.container.visible = true

      gsap.to(this.container, {
        alpha: 1,
        duration: 1,
        ease: 'power4.out',
      })
    } else {
      this.container.visible = true
    }
  }

  public hide() {
    this.container.visible = false
  }

  public async showIfVisual() {
    if (this.texturePromise) {
      await this.texturePromise
      this.show()
    } else if (this.color) {
      this.show()
    } else {
      this.hide()
    }
  }
}

class RoundedRectLayer extends Layer {
  scaleX = 1
  scaleY = 1
  rotation = 0
  skewX = 0
  skewY = 0

  public render() {
    this.graphics.clear()

    this.graphics.setTransform(
      this.x,
      this.y,
      this.scaleX,
      this.scaleY,
      this.rotation,
      this.skewX,
      this.skewY,
      this.width / 2,
      this.height / 2
    )

    if (this.active) {
      this.graphics.lineStyle({
        width: placeholderStrokeWidth,
        color: placeholderStrokeColor,
        alpha: placeholderStrokeAlpha,
        dashArray: placeholderDashArray,
        dashAuto: true,
      })
    }

    // Use texture or fillColor is any of those is provided.
    // Else render using placeholder fill and stroke.
    if (this.texture) {
      this.setTextureFillMatrixTransform()
      this.graphics.beginTextureFill({
        texture: this.texture,
        matrix: this.textureFillMatrix,
      })
    } else if (this.color) {
      this.graphics.beginFill(this.color, 1)
    } else {
      this.graphics.beginFill(placeholderFillColor, placeholderFillAlpha)
    }

    this.graphics.drawRoundedRect(0, 0, this.width, this.height, this.radius)
    this.graphics.endFill()
  }
}

// Custom layer specifically built to render the wall layer.
// It renders a rounded rectangle with varying radiuses.
// Using this.radius for the top left and top right corners and 0 for the bottom left and bottom right corners.
class WallLayer extends Layer {
  scaleX = 1
  scaleY = 1
  rotation = 0
  skewX = 0
  skewY = 0

  public render() {
    this.graphics.clear()
    this.graphics.setTransform(
      this.x,
      this.y,
      this.scaleX,
      this.scaleY,
      this.rotation,
      this.skewX,
      this.skewY,
      this.width / 2,
      this.height / 2
    )

    if (this.active) {
      this.graphics.lineStyle({
        width: placeholderStrokeWidth,
        color: placeholderStrokeColor,
        alpha: placeholderStrokeAlpha,
        dashArray: placeholderDashArray,
        dashAuto: true,
      })
    }

    // Use texture or fillColor is any of those is provided.
    // Else render using placeholder fill.
    if (this.texture) {
      this.setTextureFillMatrixTransform()
      this.graphics.beginTextureFill({
        texture: this.texture,
        matrix: this.textureFillMatrix,
      })
    } else if (this.color) {
      this.graphics.beginFill(this.color, 1)
    } else {
      this.graphics.beginFill(placeholderFillColor, placeholderFillAlpha)
    }

    // Starting from the top left corner.
    this.graphics.moveTo(this.x + this.radius, this.y)

    // Drawing the top line with top right corner.
    this.graphics.lineTo(this.x + this.width - this.radius, this.y)
    this.graphics.quadraticCurveTo(
      this.x + this.width,
      this.y,
      this.x + this.width,
      this.y + this.radius
    )

    // Drawing the right line with bottom right corner.
    this.graphics.lineTo(this.x + this.width, this.y + this.height)
    this.graphics.quadraticCurveTo(
      this.x + this.width,
      this.y + this.height,
      this.x + this.width,
      this.y + this.height
    )

    // Drawing the bottom line with bottom left corner.
    this.graphics.lineTo(this.x, this.y + this.height)
    this.graphics.quadraticCurveTo(
      this.x,
      this.y + this.height,
      this.x,
      this.y + this.height
    )

    // Drawing the left line with top left corner and closing the shape.
    this.graphics.lineTo(this.x, this.y + this.radius)
    this.graphics.quadraticCurveTo(this.x, this.y, this.x + this.radius, this.y)

    // Since this shape will likely be filled, closePath ensures that the shape is fully closed.
    this.graphics.closePath()

    this.graphics.endFill()
  }
}

// Custom layer specifically built to render the wall layer.
// It renders a trapeze with rounded bottom corners.
// Here the radius property is not absolute but a percentage of the vertical distance between top and bottom sides.
class FloorLayer extends Layer {
  baseWidth: number
  scaleX = 1
  scaleY = 1
  rotation = 0
  skewX = 0
  skewY = 0

  constructor(options: FloorLayerConstructorOptions) {
    super(options)

    this.baseWidth = options.baseWidth
  }

  public async render() {
    // Get prepared for edge color
    const edges = [3, 7, 12]
    const baseColor = (await this.colorAverage) || this.color || '#000000'

    this.graphics.clear()

    this.graphics.setTransform(
      this.x,
      this.y,
      this.scaleX,
      this.scaleY,
      this.rotation,
      this.skewX,
      this.skewY,
      this.width / 2,
      this.height / 2
    )

    // Draw edge
    if (this.texture || this.color) {
      const edgeColorLight = adjustBrightness(baseColor, 20)
      const edgeColorDark = adjustBrightness(baseColor, -60)

      // Three passes for multi-laminated edge
      this.draw(edges[2], edgeColorDark)
      this.draw(edges[1], edgeColorLight)
      this.draw(edges[0], edgeColorDark)
    }

    // Draw surface
    if (this.texture) {
      this.setTextureFillMatrixTransform(this.baseWidth)
      this.graphics.beginTextureFill({
        texture: this.texture,
        matrix: this.textureFillMatrix,
      })
    } else if (this.color) {
      this.graphics.beginFill(this.color, 1)
    }

    if (this.texture || this.color) {
      this.draw()
    }

    // Draw outline
    if (this.active) {
      this.graphics.lineStyle({
        width: placeholderStrokeWidth,
        color: placeholderStrokeColor,
        alpha: placeholderStrokeAlpha,
        dashArray: placeholderDashArray,
        dashAuto: true,
      })
    }

    if (!this.texture && !this.color) {
      // If no selection, draw placeholder fill + alpha
      this.graphics.beginFill(placeholderFillColor, placeholderFillAlpha)
    }

    this.draw(edges[2])
  }

  private draw(edge = 0, color?: string) {
    if (color) {
      this.graphics.beginFill(color, 1)
    }

    // Length difference between top and bottom edges
    const widthDifference = this.baseWidth - this.width

    const topLeft = { x: 0, y: 0 }
    const topRight = { x: this.width, y: 0 }
    const bottomRight = {
      x: this.width + widthDifference / 2,
      y: this.height + edge,
    }
    const bottomLeft = {
      x: -widthDifference / 2,
      y: this.height + edge,
    }

    // Difference ratio between the length of the vertical sides VS bottom side
    const bezierRadiusRatio =
      getDistance(topRight, bottomRight) / getDistance(bottomRight, bottomLeft)

    // Starting from the top left corner.
    this.graphics.moveTo(topLeft.x, topLeft.y)

    // Drawing the horizontal top line to top right corner.
    this.graphics.lineTo(topRight.x, topRight.y)

    // Drawing diagonal line from top right corner to bottom right bezier start.
    const bottomRightBezierStart = getPointAt(
      topRight,
      bottomRight,
      1 - this.radius
    )

    this.graphics.lineTo(bottomRightBezierStart.x, bottomRightBezierStart.y)

    // Drawing bottom right corner bezier curve.
    const bottomRightBezierStop = getPointAt(
      bottomRight,
      bottomLeft,
      this.radius * bezierRadiusRatio
    )
    this.graphics.quadraticCurveTo(
      bottomRight.x,
      bottomRight.y,
      bottomRightBezierStop.x,
      bottomRightBezierStop.y
    )

    // Drawing horizontal line from bottom right bezier stop to bottom left bezier start.
    const bottomLeftBezierStart = getPointAt(
      bottomRight,
      bottomLeft,
      1 - this.radius * bezierRadiusRatio
    )
    this.graphics.lineTo(bottomLeftBezierStart.x, bottomLeftBezierStart.y)

    // Drawing bottom left corner bezier curve.
    const bottomLeftBezierStop = getPointAt(bottomLeft, topLeft, this.radius)
    this.graphics.quadraticCurveTo(
      bottomLeft.x,
      bottomLeft.y,
      bottomLeftBezierStop.x,
      bottomLeftBezierStop.y
    )

    this.graphics.closePath()
    this.graphics.endFill()
  }
}

class TextLayer extends Layer {
  scaleX = 1
  scaleY = 1
  rotation = 0
  skewX = 0
  skewY = 0
  padding = 0
  name?: string
  headline: string
  project: string
  headlineColor = '#ffffff'
  nameColor = '#000000'
  projectColor = '#ffffff'
  // Does the floor layer of composition has texture
  hasFloor = true

  constructor(options: TextLayerConstructorOptions) {
    super(options)

    this.padding = options.padding
    this.name = options.name
    this.headline = options.headline
    this.project = options.project

    this.width -= 2 * this.padding
    this.height -= 2 * this.padding
  }

  render() {
    if (this.name === undefined) {
      return
    }

    this.container.removeChildren()

    const nameSize = 32
    const textSize = nameSize * 0.6
    const defaults: Partial<ITextStyle> = {
      fontFamily: 'KievitOT',
      fontSize: textSize,
      fontStyle: 'italic',
      align: 'center',
    }
    const group = new Container()

    group.name = 'group'
    group.setTransform(
      this.x,
      this.y,
      this.scaleX,
      this.scaleY,
      this.rotation,
      this.skewX,
      this.skewY,
      this.width / 2,
      this.height / 2
    )

    const headline = new Text(this.headline, {
      ...defaults,
      fill: this.headlineColor,
    })

    headline.x = this.width / 2 - headline.width / 2
    headline.y = this.height / 2 - headline.height / 2 - textSize / 2

    const name = new Text(this.name, {
      ...defaults,
      fontFamily: 'CirculatStd',
      fontSize: nameSize,
      fontStyle: 'normal',
      fill: this.nameColor,
      wordWrap: true,
      wordWrapWidth: this.width,
    })

    name.x = this.width / 2 - name.width / 2
    name.y = headline.y + headline.height + 4
    name.alpha = 0.7
    // name.blendMode = BLEND_MODES.ADD

    const project = new Text(this.project, {
      ...defaults,
      fill: this.projectColor,
    })

    project.x = this.width / 2 - project.width / 2
    project.y = this.height + (this.hasFloor ? 24 : -24)

    group.addChild(headline)
    group.addChild(name)
    group.addChild(project)

    this.container.addChild(group)
  }

  public async updateText(
    {
      name,
      primaryColor,
      floorColor,
    }: {
      name: string
      primaryColor?: Promise<string>
      floorColor?: Promise<string>
    },
    init = false,
    animate = true
  ) {
    this.name = name
    this.hasFloor = Boolean(floorColor)

    const light = '#ffffff'
    const dark = '#31353E'

    if (primaryColor) {
      const color = await primaryColor
      const brightness = getBrightness(color)

      this.nameColor =
        brightness > 0.25
          ? adjustBrightness(color, -180)
          : adjustBrightness(color, 180)

      this.headlineColor = brightness > 0.5 ? dark : light
    }

    if (floorColor) {
      const color = await floorColor
      const brightness = getBrightness(color)

      this.projectColor = brightness > 0.5 ? dark : light
    } else {
      this.projectColor = dark
    }

    this.render()
    init && this.show(animate)
  }
}

export { RoundedRectLayer, WallLayer, FloorLayer, TextLayer, Layer }
