import { ColorSource, Graphics, ILineStyleOptions } from 'pixi.js'

import { DashedGeometry } from './DashedGeometry'
import { DashedLineStyle } from './DashedLineStyle'

export interface IDashedLineStyleOptions extends ILineStyleOptions {
  dashArray?: number[]
  dashOffset?: number
  dashAuto?: boolean
}

export class DashedGraphics extends Graphics {
  constructor() {
    super(new DashedGeometry())

    this._lineStyle = new DashedLineStyle()
  }

  public lineStyle(
    options: IDashedLineStyleOptions | number | null = null,
    color: ColorSource = 0x0,
    alpha?: number,
    alignment = 0.5,
    native = false
  ): this {
    let opts = options

    // Support non-object params: (width, color, alpha, alignment, native)
    if (typeof options === 'number' || options === null) {
      opts = {
        width: options,
        color,
        alpha,
        alignment,
        native,
      } as IDashedLineStyleOptions
    }

    return this.lineTextureStyle(opts as IDashedLineStyleOptions)
  }
}
