






import { STEP } from '@/views/MoodboardBuilder.vue'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ProgressIndicator',
  props: {
    activeStep: {
      type: Number,
      required: true,
    },
    a11yLabel: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const currentStep = computed(() => {
      switch (props.activeStep) {
        case STEP.MOODS:
          return 1
        case STEP.WALL:
          return 2
        case STEP.FLOOR:
          return 3
        case STEP.PRIMARY:
          return 4
        case STEP.SECONDARY:
          return 5
        case STEP.TERTIARY:
          return 6
        case STEP.ACCESSORY:
          return 7
        default:
          return 1
      }
    })

    const label = computed(() =>
      props.a11yLabel
        .replace('#CURRENT_STEP#', currentStep.value.toString())
        .replace('#TOTAL_STEPS#', '7')
    )

    return {
      label,
    }
  },
})
